a, a:visited {
  color: var(--color--font);
  text-decoration: underline;
}

a:hover {
  color: var(--color--font);
  text-decoration: none;
}

a.--subtile, .header a {
  text-decoration: none;
}

a.--subtile:hover, .header a:hover {
  text-decoration: underline;
}

button {
  appearance: none;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
