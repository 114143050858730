:root {
  --font-size: 16px;
  --line-height: 1.3;
  --line-height-space: calc(var(--line-height) * 1rem);
  --padding: .25rem;
  --color--font: black;
  --color--bg: transparent;
}

@media(min-width: 768px) {
  :root {
    --padding: .5rem;
  }
}
