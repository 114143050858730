/* @font-face { */
/*   font-family: "Hershey Futural 30"; */
/*   src: url("./fonts/Hershey-Futural-30.ttf") format("truetype"); */
/* } */

html {
  font-family: Arial, sans-serif;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  line-height: inherit;
  font-style: inherit;
  font-weight: inherit;
}

h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  margin-bottom: var(--line-height-space);
}

h1 {
  font-weight: bold;
}

h2 {
  text-transform: uppercase;
}

ul, ol {
  list-style-type: none;
  padding-left: 0;
}

li {
  position: relative;
  padding-left: var(--line-height-space);
}

li:before {
  position: absolute;
  top: 0;
  left: 0;
}

ul li:before {
  content: "-";
}

ol {
  counter-reset: ol-li;
}

ol li {
  counter-increment: ol-li;
  padding-left: calc(1.25 * var(--line-height-space));
}

ol li:before {
  content: counter(ol-li) ".";
}

ul.--subtile li {
  padding-left: 0;
}

ul.--subtile li:before {
  content: "";
  position: relative;
  top: auto;
  left: auto;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

.text--indent {
  display: block;
  padding-left: var(--line-height-space);
}

@media(min-width: 375px) {
  .text--indent {
    padding-left: calc(4 * var(--line-height-space));
  }
}

.footnotes, sup, figcaption {
  font-size: .8em;
}

.footnotes hr {
  display: none;
}

.footnotes ol li {
  padding-left: calc(.75 * var(--line-height-space));
}

.footnotes ol li:before {
  content: counter(ol-li);
  top: -.25em;
}

.footnotes ol li p {
  margin-bottom: 0;
}

a.footnote-ref,
a.footnote-backref {
  text-decoration: none;
}

a.footnote-ref:hover,
a.footnote-backref:hover {
  text-decoration: underline;
}

sup {
  top: -.5em;
  line-height: 0;
  vertical-align: baseline;
}
