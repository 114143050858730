body, body.page {
  padding: var(--padding);
}

body, .page {
  color: var(--color--font);
  background: var(--color--bg);
}

.page {
  padding: calc(.4 * var(--padding));
  padding-right: .5rem;
  margin-bottom: calc(3 * var(--line-height-space));
}

@media(min-width: 768px) {
  .page {
    padding-right: calc(.4 * var(--padding));
  }
}

.page:last-of-type {
  margin-bottom: 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--padding);
}

.grid > :not(.page):not(.footer) {
  grid-column: 1/-1;
  align-self: start;
  margin-bottom: var(--line-height-space);
}

.grid > .page,
.grid > .footer {
  grid-column: 1/-1;
}

@media(min-width: 1000px) {

  .grid {
    grid-template-columns: repeat(12, 1fr);
  }

  .grid > .column {
    grid-column: auto;
    grid-column-end: span 12;
  }

  .grid > .column.column--span-a { grid-column-end: span 1; }
  .grid > .column.column--span-b { grid-column-end: span 2; }
  .grid > .column.column--span-c { grid-column-end: span 3; }
  .grid > .column.column--span-d { grid-column-end: span 4; }
  .grid > .column.column--span-e { grid-column-end: span 5; }
  .grid > .column.column--span-f { grid-column-end: span 6; }
  .grid > .column.column--span-g { grid-column-end: span 7; }
  .grid > .column.column--span-h { grid-column-end: span 8; }
  .grid > .column.column--span-i { grid-column-end: span 9; }
  .grid > .column.column--span-j { grid-column-end: span 10; }
  .grid > .column.column--span-k { grid-column-end: span 11; }
  .grid > .column.column--span-l { grid-column-end: span 12; }
  .grid > .column.column--start-1 { grid-column-start: 1; }
  .grid > .column.column--start-2 { grid-column-start: 2; }
  .grid > .column.column--start-3 { grid-column-start: 3; }
  .grid > .column.column--start-4 { grid-column-start: 4; }
  .grid > .column.column--start-5 { grid-column-start: 5; }
  .grid > .column.column--start-6 { grid-column-start: 6; }
  .grid > .column.column--start-7 { grid-column-start: 7; }
  .grid > .column.column--start-8 { grid-column-start: 8; }
  .grid > .column.column--start-9 { grid-column-start: 9; }
  .grid > .column.column--start-10 { grid-column-start: 10; }
  .grid > .column.column--start-11 { grid-column-start: 11; }
  .grid > .column.column--start-12 { grid-column-start: 12; }
}

.page > *:not(.column):not(.page):not(figure) {
  max-width: 58rem;
}
