figure {
  margin: 0;
}

.figure--iframe .figure__embed {
  position: relative;
}

.figure--iframe .figure__embed iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

img, video {
  max-width: 100%;
}

figure img, figure video {
  width: 100%;
}
